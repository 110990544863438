<template>
  <v-dialog v-model="isModalVisible" scrollable width="500px" persistent>
    <v-card class="pa-8" v-if="lead">
      <div class="d-flex justify-space-between align-content-center">
        <h1 class="font-16 mb-6">
          {{ actionList.name }}
        </h1>
        <v-btn elevation="0" type="text" color="transparent" @click="close()">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <hr style="border-color: rgba(0,0,0,0.1)">
      <v-form ref="form" v-model="valid" @submit.prevent="updateStatus">
        <v-row class="mt-5">
          <v-col cols="12">
            <v-select
                v-model="lead.leads_action_list_items_id"
                label="Action List"
                :items="actionList.steps"
                item-text="step_name"
                item-value="id"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :rules="[(v) => !!v || 'Status is required']"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-btn color="primary" type="submit">
                Update
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>

</template>
<script>
export default {
  name: "LeadChangeStatusModal",
  props: {
    isModalVisible: {type: Boolean},
    id: {type: Number}
  },
  data() {
    return {
      lead: null,
      actionList: {
        steps: []
      },
      valid: true,
    }
  },
  mounted() {
    this.getLead()
  },
  watch: {
    id(val) {
      if (val) {
        this.getLead();
      }
    },
    lead() {
      this.getActionSteps()
    }
  },
  methods: {
    getLead() {
      this.showLoader("Loading Lead");
      this.$http.get(`/venues/leads/responses/${this.$props.id}`).then(response => {
        if (response.status == 200) {
          this.lead = response.data.data;
        }
      }).catch(err => {
        this.showError(err)
      }).finally(() => {
        this.hideLoader()
      })
    },
    getActionSteps() {
      this.$http.get(`/venues/leads/action-list/${this.lead.leads_action_list_id}`).then(response => {
        if (response.status == 200) {
          this.actionList = response.data.data;
        }
      }).catch(err => {
        this.showError(err)
      })
    },
    close() {
      this.$emit('close')
    },
    updateStatus() {
      if (!this.$refs.form.validate() || this.actionList.steps.findIndex(step => step.id == this.lead.leads_action_list_items_id) < 0) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Updating...")
      this.$http.put(`/venues/leads/responses/${this.$props.id}`, {
        leads_action_list_items_id: this.lead.leads_action_list_items_id
      }).then(response => {
        if (response.status === 200) {
          this.showSuccess("Status Updated successfully");
          this.$emit('close', true)
        }
      }).catch(err => {
        this.showError(err)
      }).finally(() => {
        this.hideLoader()
      })
    }
  }
};
</script>
