<template>
  <v-dialog v-model="isModalVisible"  scrollable width="60%" persistent>
    <v-card class="pa-8 overflow-auto" v-if="lead">
      <div class="d-flex justify-space-between align-content-center">
        <h1 class="font-16 mb-6">
          {{ lead.answers.form_name }}
        </h1>
        <v-btn elevation="0" type="text" color="transparent" @click="close()">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <p class="font-12">
        {{ lead.answers.form_description }}
      </p>
      <hr style="border-color: rgba(0,0,0,0.1)">
      <v-row>
        <v-col md="6" sm="12" v-for="(field,index) in lead.answers.form_fields" :key="field.field_name">
          <p class="ma-2 font-weight-bold">
            {{ index + 1 }}. {{ field.field_name }}
          </p>
          <p class="ma-2" v-if="field.type === 'check_boxes'">
            {{ field.answer && field.answer.length ? field.answer.join(', ') : "N/A" }}
          </p>
          <p class="ma-2" v-else-if="field.type === 'drop_down'">
            {{ field.answer && field.answer.value ? field.answer.value : "N/A" }}
          </p>
          <p class="ma-2" v-else>
            {{ field.answer && field.answer ? field.answer : "N/A" }}
          </p>
          <hr style="border-color: rgba(0,0,0,0.1)">
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>

</template>
<script>
export default {
  name: "LeadResponseDetails",
  props: {
    isModalVisible: {type: Boolean},
    id: {type: Number}
  },
  data() {
    return {
      lead: null
    }
  },
  mounted() {
    this.getLead()
  },
  watch: {
    id(val) {
      if (val) {
        this.getLead();
      }
    },
  },
  methods: {
    getLead() {
      this.showLoader("Loading Lead");
      this.$http.get(`/venues/leads/responses/${this.$props.id}`).then(response => {
        if (response.status == 200) {
          this.lead = response.data.data;
        }
      }).catch(err => {
        this.showError(err)
      }).finally(() => {
        this.hideLoader()
      })
    },
    close(){
      this.$emit('close')
    }
  }
};
</script>
