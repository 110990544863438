<template>
  <v-container fluid>
    <div class="text-lg-right d-flex align-content-center justify-end gap-x-4">
      <v-btn
          height="48"
          style="background-color: #4facae;color: white; margin-right:10px"
          elevation="0"
          @click="openConfigurations"
      >
        <v-icon dark>mdi-cog</v-icon>
        Configuration
      </v-btn>
    </div>
    <v-card class="shadow px-8 py-4 rounded-5 mt-10">
      <v-row class="mt-4">
        <div
            class="mb-2 mt-2"
            style="font-size: 25px;font-weight: 600; margin-left:15px"
        >
          Leads
        </div>

        <v-spacer></v-spacer>
        <v-col md="2">
          <div style="width: 110px; float: right">
            <v-select
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                filled
                dense
                v-model="perPage"
                label="Per Page"
                :items="[10, 15, 25, 50]"
                @change="loadLeads"
            ></v-select>
          </div>
        </v-col>
      </v-row>

      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr class="tr-rounded tr-neon opacity-70">
            <th>
              Created on
            </th>
            <th>
              Country
            </th>

            <th>
              City
            </th>

            <th>
              Timezone
            </th>
            <th>
              Status
            </th>
            <th>
              Actions
            </th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(lead, i) in leads" :key="i">
            <td>
              {{ lead.created_at | timeStamp }}
            </td>
            <td>
              {{ lead.country }}
            </td>
            <td>
              {{ lead.city }}
            </td>
            <td>
              {{ lead.timezone }}
            </td>
            <td>
              <v-btn v-if="lead.lead_action_list_item" class="mt-2" small block :color="lead.lead_action_list_item.color_code" dark>

                <span style="text-transform: none">
                  {{ lead.lead_action_list_item.step_name }}
                </span>
              </v-btn>

            </td>
            <td>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      depressed
                      v-on="on"
                      dark
                      small
                      color="transparent"
                  >
                    <v-icon color="black">mdi-dots-horizontal</v-icon>
                  </v-btn
                  >
                </template>
                <v-list>
                  <v-list-item @click="viewLead(lead)">
                    <v-list-item-title>View</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="toggleStatus(lead)">
                    <v-list-item-title>Change Status</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-pagination
          v-if="totalPages > 1"
          v-model="page"
          :length="totalPages"
      ></v-pagination>

      <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
      ></confirm-model>
      <LeadResponseDetails v-if="viewLeadId && isResponseModalVisible" :id="viewLeadId"
                           :is-modal-visible="isResponseModalVisible"
                           @close="closeResponseModal"/>
      <LeadChangeStatusModal v-if="viewLeadId && isStatusModalVisible" :id="viewLeadId"
                             :is-modal-visible="isStatusModalVisible"
                             @close="closeStatusModal"/>
    </v-card>
  </v-container>
</template>
<script>
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import LeadResponseDetails from "@/views/LeadTracking/LeadResponseDetails.vue";
import LeadChangeStatusModal from "@/views/LeadTracking/LeadChangeStatusModal.vue";

export default {
  props: {},
  components: {LeadChangeStatusModal, LeadResponseDetails, ConfirmModel},
  data() {
    return {
      leads: [],
      isLoading: false,
      page: 1,
      totalPages: 1,
      perPage: 10,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      viewLeadId: null,
      isResponseModalVisible: false,
      isStatusModalVisible: false
    };
  },
  computed: {},
  mounted() {
    this.loadLeads();
  },
  watch: {
    page() {
      this.loadLeads();
    },
  },

  methods: {
    loadLeads() {
      this.isLoading = true;
      this.$http.get('venues/leads/responses', {
        params: {
          per_page: this.perPage,
          page: this.page
        }
      }).then(response => {
        if (response.status == 200) {
          const records = response.data
          this.leads = records.data;
          this.page = records.current_page ? Number(records.current_page) : 1;
          this.totalPages = records.total_pages
        }
      }).finally(() => {
        this.isLoading = false;
      })
    },
    viewLead(data) {
      this.viewLeadId = data.id;
      this.isResponseModalVisible = true
    },
    toggleStatus(data) {
      this.viewLeadId = data.id;
      this.isStatusModalVisible = true
    },
    openConfigurations() {
      this.$router.push({
        name: "leadTrackingConfiguration",
      });
    },
    confirmActions(data) {
      console.log("confirm");
      if (data.type == "close_confirm") {
        this.loadLeads();
      }
      //   this.$forceUpdate();
      this.confirmModel.id = null;
    },
    closeResponseModal() {
      this.viewLeadId = null;
      this.isResponseModalVisible = false
    },
    closeStatusModal(refresh = false) {
      this.viewLeadId = null;
      this.isStatusModalVisible = false
      if (refresh) {
        this.loadLeads()
      }
    }
  },
};
</script>

<style scoped lang="scss">
.tr-rounded {
  th:first-child,
  td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  th:last-child,
  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.tr-neon {
  td,
  th {
    background: #4faeaf !important;
  }

  &.opacity-70 {
    td,
    th {
      background: rgba(233, 241, 246, 0.7) !important;
    }
  }
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(70, 76, 136, 0.2) !important;
}

.v-card {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.rounded-5 {
  border-radius: 1.25rem !important;
}

th {
  font-size: 11px !important;
}
</style>
